import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GA from 'react-ga';
import * as Analytics from '@vercel/analytics';
import { Footer, Blog, Possibility, Features, WhatGPT3, Team, Header, Projects } from './containers';
import { CTA, Navbar, Contact, Brand } from './components';
import './App.css';

const GA_TRACKING_ID = 'G-XV6G77EE0X'; // Replace with your Google Analytics tracking ID
const VERCEL_PROJECT_ID = 'prj_2ozRnQioeeEA4n4zjPGyR5HM3KFG'; // Replace with your Vercel project ID

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    GA.initialize(GA_TRACKING_ID);
    if (!GA.gaInitialized) {
      console.error('Error initializing Google Analytics');
    }
  }, []);

  const currentPath = window.location.pathname;

  const renderComponent = () => {
    switch (currentPath) {
      case '/projects':
        GA.pageview(currentPath);
        Analytics.track('Page View', {
          path: currentPath,
          projectId: VERCEL_PROJECT_ID, // Include Vercel project ID here
        });
        return <Projects className="App" />;
      default:
        GA.pageview(currentPath);
        Analytics.track('Page View', {
          path: currentPath,
          projectId: VERCEL_PROJECT_ID, // Include Vercel project ID here
        });
        return (
          <div className="App">
            <Router>
              <div className="gradient__bg">
                <Navbar />
                <Header />
              </div>
              <WhatGPT3 />
              <Features />
              <div className="gradient__bg_alt">
                <Possibility />
              </div>
              <Team />
              <CTA />
              <Brand />
              <Blog />
              <Contact />
              <Footer />
            </Router>
          </div>
        );
    }
  };

  return renderComponent();
};

export default App;
